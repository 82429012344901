import Swiper, { Navigation, Pagination } from 'swiper';

import { breakpoints } from '@js/breakpoints';

Swiper.use([Navigation, Pagination]);

export default class ServicesSlider {
  constructor(rootNode) {
    this.root = rootNode;

    this.initSlider();
  }

  initSlider() {
    const swiper = new Swiper(this.root.querySelector('.swiper'), {
      spaceBetween: 25,
      slidesPerView: 'auto',
      navigation: {
        nextEl: this.root.querySelector('.button-next'),
        prevEl: this.root.querySelector('.button-prev'),
      },
      pagination: {
        el: this.root.querySelector('.pagination'),
        type: 'fraction',
      },
      breakpoints: {
        1280: {
          slidesPerView: 3,
        },
      },
    });
  }
}
