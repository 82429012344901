import { breakpoints } from '@js/breakpoints';

const COLLAPSE_TIME = 700; //ms

export default class Header {
  constructor(rootNode) {
    this.root = rootNode;

    this.initMenuToggle();
    this.initAccordion();
  }

  initMenuToggle() {
    const button = this.root.querySelector('#toggle-nav');
    const nav = this.root.querySelector('nav');

    button.addEventListener('click', () => {
      nav.classList.toggle('open');
      button.classList.toggle('open');
    });
  }

  initAccordion() {
    let active = -1;

    const buttons = Array.from(this.root.querySelectorAll('.nav-button'));

    const targets = buttons.map((button) => {
      const targetSelector = button.getAttribute('aria-controls');
      return this.root.querySelector(targetSelector);
    });

    targets.forEach((target) => {
      target.style.setProperty('height', `0px`);
    });

    buttons.forEach((button, i) => {
      if (!targets[i].querySelector('.submenu-column')) return;

      button.addEventListener('click', () => {
        const isFirstTime = active === -1;

        if (!isFirstTime) {
          const oldButton = buttons[active];
          const oldTarget = targets[active];
          this.closeAccordionElement(oldButton, oldTarget);
        }

        if (active === i) return (active = -1); //close if clicked again;

        active = i;
        const newButton = buttons[active];
        const newTarget = targets[active];

        const isDesktop = innerWidth >= breakpoints.xl;

        setTimeout(
          () => this.openAccordionElement(newButton, newTarget),
          !isFirstTime && isDesktop ? COLLAPSE_TIME : 0
        );
      });
    });
  }

  openAccordionElement(button, target) {
    button.setAttribute('aria-expanded', 'true');

    const height = target.scrollHeight;
    target.style.setProperty('height', `${height}px`);
  }

  closeAccordionElement(button, target) {
    button.setAttribute('aria-expanded', 'false');

    target.style.setProperty('height', `0px`);
  }
}
