export default class Accordion {
  constructor(rootNode) {
    this.root = rootNode;
    this.initAccordion();
  }

  initAccordion() {
    const buttons = this.root.querySelectorAll('.accordion-header');

    buttons.forEach((button) => {
      button.addEventListener('click', (e) => {
        const parent = button.parentElement;

        const isOpen = button.classList.contains('active');
        button.classList.toggle('active');

        const content = parent.querySelector('.accordion-content');
        const height = isOpen ? 0 : content.scrollHeight;

        content.style.setProperty('height', `${height}px`);
      });
    });
  }
}
