import Swiper from 'swiper';

export default class KeyFactsSlider {
  constructor(rootNode) {
    this.root = rootNode;

    this.initSlider();
  }

  initSlider() {
    const swiper = new Swiper(this.root.querySelector('.swiper'), {
      spaceBetween: 25,
      slidesPerView: 'auto',
      breakpoints: {
        1280: {
          slidesPerView: 4,
        },
      },
    });
  }
}
