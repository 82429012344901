import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

export default class ImageTeaserSlider {
  constructor(rootNode) {
    this.root = rootNode;
    this.initSlider();
  }

  initSlider() {
    const swiper = new Swiper(this.root.querySelector('.swiper'), {
      spaceBetween: 20,
      slidesPerView: 'auto',
      navigation: {
        nextEl: this.root.querySelector('.button-next'),
        prevEl: this.root.querySelector('.button-prev'),
      },
      pagination: {
        el: this.root.querySelector('.pagination'),
        type: 'fraction',
      },
      breakpoints: {
        1280: {
          slidesOffsetAfter: 490
        },
        1440: {
          slidesOffsetAfter: 600
        },
        1680: {
          slidesOffsetAfter: 620
        }
      }
    });
  }
}
