import * as alpine from './alpine';
import * as components from './components';
import './lazy-loading';

// ACC namespace for global configs
const ACC = window.ACC || {
  vars: {},
  methods: {
    initializeComponents: () => {
      const componentNodes =
        Array.from(document.querySelectorAll('[data-component]')) || [];
      componentNodes.forEach((node) => {
        const componentName = node.getAttribute('data-component');
        let props = node.getAttribute('data-props');
        if (props) props = JSON.parse(props);

        if (!!components[componentName]) {
          /* eslint-disable no-new */
          new components[componentName](node, props);
        }
      });
    },
  },
};

// load/initialize complex js-components
document.addEventListener('DOMContentLoaded', () =>
  ACC.methods.initializeComponents()
);
