import Alpine from 'alpinejs';
import { Loader } from '@googlemaps/js-api-loader';
import collapse from '@alpinejs/collapse';
import persist from '@alpinejs/persist';

window.GoogleMapsLoader = Loader;

Alpine.plugin(collapse)
Alpine.plugin(persist)

window.Alpine = Alpine;
window.Alpine.start();
