export const breakpoints = {
  xs: 380,
  sm: 480,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1440,
  xxxl: 1680,
  max: 1920,
};
